import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import Form from "../components/stay-connected-form";
import Modal from 'react-modal';
import closeIcon from '../_images/icons/white/close.svg';
import ReactGA from 'react-ga';
import DOD from '../components/dods';

// import Frame1 from '../_images/profiles/tassel-2.jpg';
// import Frame2 from '../_images/profiles/tassel-3.jpg';
import socialBanner from '../_images/profiles/opportunity-social.jpg';

Modal.setAppElement('#___gatsby')

export default class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      width: 0,
      height: 0
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.logger = this.logger.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Receive Updates'
    });
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  logger(e) {
    // ga('send', 'event', 'Button', 'Give Now', 'Campaign Profile');
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: e.currentTarget.textContent
    });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  
  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    var pageTitle = 'Titan of Purpose';
    var pageDesc = 'At Cal State Fullerton, Karla completed her fourth degree — a doctorate in education —  and  awakened to social justice.';

    // console.log('width: ' + this.state.width );
    var windowHeight = {
      minHeight: this.state.height
    }
    return (
      <React.Fragment>
      <Layout locationInfo={this.props.location}>
        <Helmet>
          <title>{pageTitle}</title>
          <body className="profile-landing-page" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />

          <link href="https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&display=swap" rel="stylesheet"></link>
        </Helmet>
        <section className="landing-wrap opportunity" style={windowHeight}>
          <div className="profile-text" style={windowHeight}>
            <div className="text-wrap">
              
              <div className="row">
                <h1>{pageTitle}</h1>
                <div className="col-3">  
                  <blockquote>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>“</title><path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"/></svg>
                    <p>“Cal State Fullerton changes the world one student at a time. Professors pressed me forward and showed care through mentoring. My research taught me to recognize injustice and to resist fiercely.”</p>
                  </blockquote>
                  <div className="clear"></div>
                  
                </div>
                <div className="col-9">

                  <p>Karla Aguirre was raised in fear and uncertainty because of political unrest in her parents’ home country.</p> 

                  <p>Education was her path to the American Dream. As a teen parent, she enrolled in community college and persevered to become the first in her family to earn a college degree, paving the way for other relatives.</p>

                  <p>At Cal State Fullerton, Karla completed her fourth degree — a doctorate in education —  and  awakened to social justice. Through her mentors and research on educational practices in post-apartheid South Africa, she was empowered to fight against marginalization and strive for equity and opportunity for all.</p>

                  <p>Today, Karla lives her purpose as a school counselor and founder of a nonprofit youth mentoring program, modeling resilience and championing an equitable future for all.</p>

                  <h2>Be the difference in a Titan's life.</h2>
                  <p><strong>Support the College of Education today.</strong></p>
                  <ul className="actions">
                    <li><a href="https://give.fullerton.edu/85000" className="buttonBlue" onClick={this.logger}>Donate Now</a></li>
                    <li><button className="buttonBlue outline" onClick={this.openModal}>Receive Updates</button></li>
                  </ul>

                  <DOD college="education" layout="2"  />

                </div>
              </div>
              
            </div>

            

          </div>
          {/* <img src={Frame1} className="frame animated fadeIn" id="frame1" alt="Female student in cap and gown holding diploma up" /> */}
          {/* <img src={Frame2} className="frame animated fadeIn" id="frame2" alt="Hispanic students in cap and gown during commencement" /> */}
         
        </section>

        <Modal
          closeTimeoutMS={400}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Receive Updates"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
          <div className="wrap">
            <Form profile="purpose" college="the College of Education" />
          </div>
        </Modal>
        
      </Layout>
      </React.Fragment>
    );
  }
}
